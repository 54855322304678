<!--  -->
<template>
  <div style="width: 100%; height: 95%">
    <div style="margin-bottom: 10px; width: 100%">
      <el-date-picker style="width: 100%" v-model="value1" type="daterange" value-format="yyyy-MM-dd" @change="changeList"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="mini">
      </el-date-picker>
    </div>

    <div id="myChart2" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { PostCommoditySales } from "@/api/index.js"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    list: Object,
  },
  data() {
    //这里存放数据
    return {
      list2: [],
      value1: [],
      myChart: "",
      option: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //获取上一个星期日期
    getLastMonth(date) {
      //  var now = new Date();
      //     var date = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
      //     var year = date.getFullYear();
      //     var month = (date.getMonth() + 1).toString();
      //     if (month.length === 1) {
      //         month = "0" + month
      //     }
      //     var day = (date.getDate()).toString();
      //     if (day.length === 1) {
      //         day = "0" + day
      //     }
      //     return year + '-' + month + '-' + day;
      var arr = date.split('-');
      var year = arr[0]; //获取当前日期的年份
      var month = arr[1]; //获取当前日期的月份
      var day = arr[2]; //获取当前日期的日
      var days = new Date(year, month, 0);
      days = days.getDate(); //获取当前日期中月的天数
      var year2 = year;
      var month2 = parseInt(month) - 1;
      if (month2 == 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
        day2 = days2;
      }
      if (month2 < 10) {
        month2 = '0' + month2;
      }
      var t2 = year2 + '-' + month2 + '-' + day2;
      return t2;
    },
    //获取当前年月日
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      var day = date.getDate(); //获取当前日(1-31); //获取当前月份(0-11,0代表1月)
      var time = year + '-' + month + '-' + day;  //YYYY-MM-dd
      return time;//返回当前日期YYYY-MM-dd
    },
    //热销商品销量数据统计
    changeList() {
      //console.log(this.value1)
      this.PostCommoditySales({
        start_date: this.value1[0],
        end_date: this.value1[1],
      })
    },
    async getList() {
      // let info = await this.$axios.post("cheshia");
      // console.log(info);
      // this.list2 = info.data.data;
      this.drawLine();
    },
    drawLine() {
      var chartDom = document.getElementById("myChart2");
      this.myChart = this.$echarts.init(chartDom);
      var option;
      const colors = ['#a6c30f', '#5470C6', '#1E90FF'];
      option = {
        title: { text: "热销商品统计表" },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: {
          type: 'category',
             axisLabel: {  
          		interval:0,
              rotate:-25  //倾斜的程度
          },
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          //    axisLabel: {
          //     interval: 0,
          //     formatter:function(value){  
          //        return value.split("").join("\n");  
          //     }
          // }
        },
        grid: {
          right: '10%'
        },
        legend: {
          data: ['销量额/元', '销售量/份']
        },
        yAxis: [
          {
            type: 'value',
            name: '销售额/元',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[2]
              }
            }
          },
          {
            type: 'value',
            name: '销售量/份',
            position: 'right',
            offset: 0,
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            },
            
          },

        ],
        series: [
          {
            name: '销售量/份',
            type: 'line',
            // smooth: true,
            yAxisIndex: 1,
            stack: 'Search Engine',
            data: [],
            itemStyle: {
              color: colors[0]
            },
            // label: {
            //   show: true,
            //   position: 'top'
            // },
          },
          {
            name: '销量额/元',
            data: [120, 123],
            type: 'bar',
            yAxisIndex: 0,
            itemStyle: {
              color: colors[2]
            },
            label: {
              show: true,
              position: 'top'
            },
            stack: 'Search Engine',
          },

        ]
      };
      // option.xAxis.data = this.list2.mobile;
      // option.series[0].data = this.list2.member;
      this.option = option;
      option && this.myChart.setOption(option);
    },
    // --------------------------------网络请求函数----------------------------------
    //热销商品网络请求函数
    async PostCommoditySales(data) {
      let res = await PostCommoditySales(data);
      if (res.data.code == 1) {
        var numArr = [];//销量数组
        var dateArr = [];//X轴日期数组
        var totalArr = [];//销售额
        res.data.data.forEach(item => {
          numArr.push(item.sales_num);
          dateArr.push(item.c_name);
          totalArr.push(item.total_payment_amount);
        });
        // console.log(numArr,date)
        //配置echars
        this.option.series[1].data = totalArr;
        this.option.series[0].data = numArr;
        this.option.xAxis.data = dateArr;
        this.myChart.setOption(this.option);
        this.$message({
          message: res.data.message,
          type: 'success'
        });
      } else {
        this.$message.error(res.data.message);
      }
      console.log(res)
    }
  },
  beforeCreate() { }, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var endDate = this.getDate();
    var stratDate = this.getLastMonth(endDate);
    console.log(stratDate)
    this.value1.push(stratDate);
    this.value1.push(endDate);
    console.log(this.value1)
    this.PostCommoditySales({
      end_date: this.value1[1],
      start_date: this.value1[0]
    });
  },
  beforeMount() { }, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* #myChart {
  height: 100%;
  width: 100%;
} */
</style>