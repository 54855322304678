<!--  -->
<template>
  <div style="width: 100%; height: 100%">
    <div style="margin-bottom: 10px; width: 100%">
      <el-date-picker
        @change="changeList()"
        v-model="value1"
        value-format="yyyy-MM-dd" 
        type="date"
        placeholder="选择日期"
        size="mini"
      >
      </el-date-picker>
    </div>
    <div id="myChart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {getWeekSales,PostDailySales} from "@/api/index.js"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    list: Object,
  },
  data() {
    //这里存放数据
    return {
      list2: [],
      value1: "",
      myChart: "",
      option: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //获取当前年月日
    getDate(){
       var date = new Date();
       var year=date.getFullYear(); //获取完整的年份(4位)
       var month=date.getMonth()+1; //获取当前月份(0-11,0代表1月)
       var day=date.getDate(); //获取当前日(1-31); //获取当前月份(0-11,0代表1月)
       var time=year+'-'+month+'-'+day;  //YYYY-MM-dd
       return time;//返回当前日期YYYY-MM-dd
    },
    async getList() {
      // let info = await this.$axios.post("cheshia");
      // console.log(info);
      // this.list2 = info.data.data;
      this.drawLine();
    },
    //本周销量数据统计
    changeList() {
      //连接日期获取周数据网络请求
      // console.log(this.value1)
      this.PostDailySales({
      date:this.value1
    });
    },
    drawLine() {  
      var chartDom = document.getElementById("myChart");
      this.myChart = this.$echarts.init(chartDom);
      var option;
      const colors = ['#a6c30f','#5470C6','#1E90FF' ];
option = {
  color: colors,
  title: {
    text: '当日销售统计表'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    }
  },
  // grid: {
  //   right: '20%'
  // },
  legend: {
    data: ['销售额/元', '销售量/份', '客单价/元']
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      // prettier-ignore
      data: ['10：00', '11:00','12：00', '13:00','14：00','15:00', '16：00','17：00','18：00','19:00', '20：00','21:00', '22：00']
    }
  ],
  yAxis: [
    {
       type: 'value',
      data:[0,50,100,150,200,250,300],
      name: '销售量/份',
      position: 'right',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[0]
        }
      },
    },
    {
      type: 'value',
      name: '客单价/元',
      position: 'right',
      offset: 40,
      nameLocation:'end',
      nameTextStyle:{
        align:'left'
      },
      alignTicks: true,
      axisLine: {
        show: true, 
        lineStyle: {
          color: colors[1]
        }
      }
    },
  {
      type: 'value',
      name: '销售额/元',
      position: 'left',
      alignTicks: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: colors[2]
        }
      }
    },
  ],
  series: [
    {
      name: '销售量/份',
      type: 'line',
      yAxisIndex: 0,
      data: [
        2.00,3.00,4.00,1.00,2.00,1.00,3.00,6.00,3.00,4.00,4.00,2.00,2.00,3.00
      ]
    },
    {
      name: '客单价/元',
      type: 'line',
      stack: 'Search Engine',
      yAxisIndex: 1,
      data: [38.00, 66.00, 100.00, 128.00, 46.00, 200.00, 100.00, 100.00, 38.00, 46.00, 238.00, 99.00,18.00]
    },
    {
      name: '销售额/元',
      type: 'bar',
      stack: 'Search Engine',
      yAxisIndex: 2,
      smooth: true,
 
      label: {
        show: true,
        position: 'top',
        formatter:num=>{
          if(num.value>0){
            return num.value
          }else{
            return ''
          }
        }
      },
      data: [
        500.00,200.00,300.00,100.00,180.00,100.00,558.00, 200.00,300.00,100.00,400.00,180.00,100.00,58.00
      ]
    },
  ]
}
      this.option = option;
      this.myChart.setOption(this.option);
    },
        //--------------------------网络请求函数------------------------------
    //根据日期获取周数据
    async PostDailySales(data){
      let res=await PostDailySales(data);
      if(res.data.code==1){
        //获取数据中的销量数据num
        var numArr=[];//订单数数组
        var dateArr=[];//X轴日期数组
        var moneyArr=[];//销售额数组
        var orderArr=[];//客单价数组
        res.data.data.result.forEach(item => {
          numArr.push(item.order_number);
          dateArr.push(item.start_date);
          moneyArr.push(item.payment_amount);
          orderArr.push(item.max_payment_amount);
        });
        // console.log(numArr,date)
          //配置echars
        this.option.series[0].data=numArr;
        this.option.series[2].data=moneyArr;
        this.option.series[1].data=orderArr;
        this.option.xAxis[0].data=dateArr;
        this.myChart.setOption(this.option);
        this.$message({
          message: res.data.message,
          type: 'success'
        });
      }else{
        this.$message.error(res.data.message);
      }
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
     //当前时间赋值给本周销量时间表
     this.value1=this.getDate();
    //获取当前当日销量数据请求
     this.PostDailySales({
      date:this.value1
    });
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* #myChart {
  height: 100%;
  width: 100%;
} */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}
</style>