<!--  -->
<template>
  <div style="width: 100%; height: 100%">
    <div style="margin-bottom: 10px; width: 100%">
      <el-date-picker
        v-model="value1"
        type="daterange"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        size="mini"
        @change="changeList"
      >
      </el-date-picker>
    </div>
    <div id="myChartClass" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { PostCommodityClassSales } from "@/api/index.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    list: Object,
  },
  data() {
    //这里存放数据
    return {
      list2: [],
      value1: [],
      myChart: "",
      option: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
        //获取上一个月日期
    getLastMonth(date) {
        var arr = date.split('-');
    var year = arr[0]; //获取当前日期的年份
    var month = arr[1]; //获取当前日期的月份
    var day = arr[2]; //获取当前日期的日
    var days = new Date(year, month, 0);
    days = days.getDate(); //获取当前日期中月的天数
    var year2 = year;
    var month2 = parseInt(month) - 1;
    if (month2 == 0) {
        year2 = parseInt(year2) - 1;
        month2 = 12;
    }
    var day2 = day;
    var days2 = new Date(year2, month2, 0);
    days2 = days2.getDate();
    if (day2 > days2) {
        day2 = days2;
    }
    if (month2 < 10) {
        month2 = '0' + month2;
    }
    var t2 = year2 + '-' + month2 + '-' + day2;
    return t2;
    },
    //获取当前年月日
    getDate() {
      var date = new Date();
      var year = date.getFullYear(); //获取完整的年份(4位)
      var month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      var day = date.getDate(); //获取当前日(1-31); //获取当前月份(0-11,0代表1月)
      var time = year + "-" + month + "-" + day; //YYYY-MM-dd
      return time; //返回当前日期YYYY-MM-dd
    },
    //连接日期获取分类数据网络请求
    changeList() {
      console.log(this.value1);
      this.PostCommodityClassSales({
        start_date: this.value1[0],
        end_date: this.value1[1],
      });
    },
    async getList() {
      // let info = await this.$axios.post("cheshia");
      // console.log(info);
      // this.list2 = info.data.data;
      this.drawLine();
    },
    drawLine() {
      var chartDom = document.getElementById("myChartClass");
      this.myChart = this.$echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "销售分布统计表/元"
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "销售分布统计表/元",
            type: "pie",
            radius: "50%",
            label: {
        alignTo: 'edge',
        formatter: '{b}（{d}%）\n销量额：{time|{c} }',
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        rich: {
          time: {
            fontSize: 12,
            color: '#000'
          }
        }
      },
       //标签
      //  itemStyle : {
      //           normal : {
      //               label:{
      //                   show : true,
      //                   position : 'inner',
      //                   formatter: '{d}%',
      //                   distance : 0.7, //这项是标识距离中心点的距离
      //                   textStyle : {                   
      //                       align : 'center',
      //                       baseline : 'middle',
      //                       fontFamily : '微软雅黑',
      //                       fontSize : 10,
      //                       fontWeight : 'bolder'
      //                    }
      //               },
      //               labelLine:{
      //                   show : false
      //               }
      //           }
      //       },
            data: [
              { value: 1048, name: "Search Engine" },
              { value: 735, name: "Direct" },
              { value: 580, name: "Email" },
              { value: 484, name: "Union Ads" },
              { value: 300, name: "Video Ads" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      // option.xAxis.data = this.list2.mobile;
      // option.series[0].data = this.list2.member;
      this.option = option;
      option && this.myChart.setOption(option);
    },
    //-------------------------------网络请求函数---------------------------------------
    //分类商品网络请求函数
    async PostCommodityClassSales(data) {
      let res = await PostCommodityClassSales(data);
      if (res.data.code == 1) {
        var dataArr = []; //数据数组
        res.data.data.forEach((item) => {
          dataArr.push({
            value: item.class_sales_amount,
            name: item.class_name,
            percentage:item.percentage
          });
        });
        //配置echars
        this.option.series[0].data = dataArr;
        this.myChart.setOption(this.option);
        this.$message({
          message: res.data.message,
          type: "success",
        });
      } else {
        this.$message.error(res.data.message);
      }
      console.log(res);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
       var endDate=this.getDate();
    var stratDate=this.getLastMonth(endDate);
    this.value1.push(stratDate);
    this.value1.push(endDate);
    this.PostCommodityClassSales({
      end_date:this.value1[1],
      start_date:this.value1[0]
    });
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped>
/* #myChart {
  height: 100%;
  width: 100%;
} */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 100%;
}
</style>