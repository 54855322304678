import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/user/userInfo', data)
}
export function getAdd(data) {
    return post('admin/user/userInfo', data)
}
export function getDel(data) {
    return post('admin/user/userInfo', data)
}
export function getEdit(data) {
    return post('admin/user/userInfo', data)
}
export function getOption(data) {
    return post('admin/user/userInfo', data)
}
//根据日期获取周数据
export function getWeekSales(data) {
    return post('admin/Statistics/weekSales', data)
  }
  //热销商品销量统计
export function PostCommoditySales(data) {
    return post('admin/Statistics/commoditySales', data)
  }
    //分类销量统计数据
export function PostCommodityClassSales(data) {
    return post('admin/Statistics/commodityClassSales', data)
  }
      //订单总数统计数据
export function PostDateSales(data) {
    return post('admin/Statistics/dateSales', data)
  }
      //当日数据统计数据
export function PostDailySales(data) {
    return post('admin/Statistics/dailySales', data)
  }